.alert-container {
  position: fixed;
  right: 0;
  top: var(--header-height);
  z-index: 1051;

  > .messages {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    perspective: 1000px;
  }

  @media (--tablet-) {
    &:not([hidden]) + main {
      margin-top: 3.875rem;
    }
  }
}
