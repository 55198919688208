.button {
  align-items: center;
  background-color: var(--color-background);
  border-color: transparent;
  border-radius: var(--field-border-radius);
  border-width: 1px;
  box-shadow: var(--focusable-box-shadow);
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-medium);
  justify-content: center;
  line-height: 1.25rem;
  min-height: 3rem;
  min-width: 1rem;
  overflow: hidden;
  padding: 0.75rem 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  @media (--mouse) {
    &:not(:disabled):hover {
      text-decoration: none;
    }
  }

  &:not(:disabled):focus {
    border-color: var(--color-focus-border);
    box-shadow: var(--focused-box-shadow);
    outline: none;
  }

  &.wide {
    width: 100%;
  }

  &.small {
    font-size: var(--font-size-small);
    padding: 0.5em;
  }

  &.primary {
    background-color: var(--button-primary-background-color);
    border-color: var(--button-primary-border-color);
    color: var(--button-primary-color);
    font-weight: var(--bold);

    @media (--mouse) {
      &:not(:disabled):hover {
        background-color: var(--button-primary-hover-background-color);
      }
    }
  }

  &.secondary {
    background-color: var(--button-secondary-background-color);
    border-color: var(--button-secondary-border-color);
    border-style: solid;
    border-width: 1px;
    color: var(--button-secondary-color);
    font-weight: var(--bold);

    @media (--mouse) {
      &:not(:disabled):hover {
        background-color: var(--button-secondary-hover-background-color);
      }
    }
  }

  &.flat {
    background-color: transparent;
    border-width: 0;
    color: var(--color-brand);

    @media (--mouse) {
      &:hover {
        /* TODO: */
      }
    }
  }

  &.alert {
    color: var(--button-alert-color);

    @media (--mouse) {
      &:not(:disabled):hover {
        color: var(--button-alert-hover-color);
      }
    }
  }

  &.hyperlink {
    border: none;
    border-radius: 0;
    color: var(--color-text);
    line-height: 1.25rem;
    min-height: unset;
    overflow: visible;
    padding: 0 var(--gap-tiny);

    &:focus {
      box-shadow: none;
    }

    &.active {
      border-bottom: 2px solid var(--button-primary-background-color);
    }
  }

  &.option-like {
    align-items: center;
    border: 1px solid var(--field-border-color);
    color: var(--color-text);
    display: flex;
    flex-direction: row;
    height: 3.75rem;
    justify-content: flex-start;
    position: relative;
    top: calc(var(--gap-small) * (-1));
    width: 100%;

    @media (--mouse) {
      cursor: pointer;

      &:hover {
        border-color: var(--button-primary-hover-background-color);
      }
    }

    > svg {
      background-color: var(--color-background);
      border-radius: 50%;
      height: 3.25rem;
      width: 3.25rem;
    }
  }

  &.primary:focus,
  &.secondary:focus,
  &:focus {
    border-color: var(--color-focus-border);
    outline: none;
  }

  &.primary:disabled,
  &.secondary:disabled,
  &:disabled {
    cursor: default;
    opacity: 0.25;
  }

  &.pending {
    &::after {
      animation: loading 2s linear infinite;
      border: 0.125rem solid var(--button-secondary-background-color, #fff);
      border-radius: 100%;
      border-top-color: transparent;
      content: '';
      height: var(--gap);
      position: absolute;
      transform: translate(50%, 50%);
      width: var(--gap);
    }

    &.hyperlink,
    &.secondary,
    &.inline {
      &::after {
        border-color: var(--button-primary-background-color);
        border-top-color: transparent;
      }
    }
  }

  &.inline {
    background-color: transparent;
    color: inherit;
    display: inline-flex;
    font-size: inherit;
    line-height: inherit;
    min-height: 0;
    overflow: visible;
    padding: 0 var(--gap-tiny);
    vertical-align: inherit;

    &:not([disabled]) {
      color: var(--color-link);
      cursor: pointer;
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }

  > svg {
    display: block;
    fill: currentColor;
    flex-grow: 0;
    flex-shrink: 0;
    height: 1rem;
    margin: 0 0.5rem;
    width: 1rem;

    path {
      fill: currentColor;
    }

    &.left {
      margin: 0 0.5rem 0 0;
    }

    &.right {
      margin: 0 0 0 0.5rem;
    }
  }

  &.primary > svg {
    margin: 0 0.5rem 0 0;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
