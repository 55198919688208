.dialog-bg {
  --dialog-backdrop-color: rgb(0 0 0 / 0.5);

  background-color: var(--dialog-backdrop-color);
  display: flex;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1040;
}

.dialog {
  background-color: var(--color-background);
  border: none;
  border-radius: var(--section-border-radius);
  box-shadow: 0 4px 30px 0 rgb(26 34 61 / 0.2);
  display: grid;
  margin: auto;
  max-height: 92vh;
  max-width: 37.5rem;
  overflow: auto;
  padding: var(--gap);
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1050;

  & > header {
    --close-button-width: 1.25rem;

    background-color: var(--color-background);
    padding-top: var(--gap);
    position: sticky;
    top: 0;
    z-index: 10;

    + * {
      margin-top: var(--gap);
    }

    & > h2 {
      margin-bottom: var(--gap);
      max-width: calc(100% - var(--close-button-width));
      text-transform: uppercase;
    }

    & > .close {
      border: none;
      height: auto;
      position: absolute;
      right: var(--gap);
      width: auto;
    }
  }

  @media (--tablet-) {
    width: auto;
  }
  @media (--phone) {
    margin: auto;
    max-width: 95vw;
  }
}

.noScroll {
  overflow: hidden;

  @media (--desktop) {
    padding-right: 15px;
  }
}
