.icon-button {
  align-items: center;
  appearance: none;
  background: transparent none;
  border: 2px solid transparent;
  border-radius: 50%;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 2rem;
  justify-content: center;
  padding: 0;
  transition: all 0.3s ease;
  width: 2rem;

  &.inline {
    height: auto;

    > svg {
      height: 0.95rem;
    }
  }

  &:active {
    svg {
      transform: scale(0.95);
    }
  }

  &:focus {
    outline: none;

    & > svg {
      color: var(--color-focus-border);
    }
  }

  @media (--mouse) {
    &:hover {
      background-color: var(--color-hover-highlight);
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  & > svg {
    fill: currentColor;
    height: 1rem;
    transition: transform 0.2s ease;
    width: 1rem;
  }

  &.pending {
    position: relative;

    &::after {
      animation: loading 2s linear infinite;
      border: 0.125rem solid var(--button-primary-hover-background-color, #fff);
      border-radius: 100%;
      border-top-color: transparent;
      content: '';
      height: var(--gap);
      position: absolute;
      transform: translate(50%, 50%);
      width: var(--gap);
    }
  }
}
