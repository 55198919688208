.alert-message {
  --alert-color: #fff;
  --alert-success-background-color: #089f27;
  --alert-warning-background-color: #c5b329;
  --alert-error-background-color: #f00;

  align-items: center;
  display: flex;
  font-size: var(--font-size-small);
  justify-content: flex-start;
  margin-bottom: var(--gap-tiny);
  max-width: 100%;
  min-height: 3rem;
  min-width: 0;
  right: 0;

  &.error {
    background-color: var(--alert-error-background-color);
    color: var(--alert-color);
  }

  &.warning {
    background-color: var(--alert-warning-background-color);
    color: var(--alert-color);
  }

  &.success {
    background-color: var(--alert-success-background-color);
    color: var(--alert-color);
  }
  /* ANIMATION */
  &.message-enter {
    animation: swing-in-right-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation-play-state: paused;
  }

  &.message-enter-active {
    animation-play-state: running;
  }

  &.message-exit {
    animation: swing-out-right-bck 0.45s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
    animation-play-state: paused;
  }

  &.message-exit-active {
    animation-play-state: running;
  }
  /* CHILDREN */
  & > span {
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 1.1875rem;
    padding: 0.75rem 2rem;
    text-align: center;

    > a {
      margin-left: 0.25rem;
    }
  }

  & > button {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: auto;
  }
}

@keyframes swing-in-right-fwd {
  0% {
    opacity: 0;
    transform: rotateY(-100deg);
    transform-origin: right;
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
    transform-origin: right;
  }
}
@keyframes swing-out-right-bck {
  0% {
    opacity: 1;
    transform: rotateY(0);
    transform-origin: right;
  }

  100% {
    opacity: 0;
    transform: rotateY(-100deg);
    transform-origin: right;
  }
}
