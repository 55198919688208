/* stylelint-disable no-duplicate-selectors */

@custom-media --phone (max-width: 47.999rem);
@custom-media --tablet (min-width: 48rem);
@custom-media --tablet- (max-width: 62.999rem);
@custom-media --tablet-only (min-width: 48rem) and (max-width: 62.999rem);
@custom-media --desktop (min-width: 63rem);
@custom-media --desktop- (max-width: 83.999rem);
@custom-media --desktop-only (min-width: 63rem) and (max-width: 83.999rem);
@custom-media --desktop-large (min-width: 84rem);
@custom-media --height-laptop (max-height: 37.5rem);
@custom-media --mouse (pointer: fine);
@custom-media --landscape (orientation: landscape);
@custom-media --portrait (orientation: portrait);
/*

------------------------------------------------- FONTS ----------------------------------------------------------

*/
/**
 * @tokens Font Family
 * @presenter FontFamily
 */

:root {
  --font-family: roboto, -apple-system, blinkmacsystemfont, 'Segoe UI', helvetica, arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-heading: 'Merriweather', serif;
}
/**
 * @tokens Font Sizes
 * @presenter FontSize
 */

:root {
  --font-size-base-phone: 16px;
  --font-size-base-tablet: 16px;
  --font-size-base-desktop: 16px;
  --font-size-base-desktop-large: 16px;

  --font-size-x-huge: 3rem; /* 48px */
  --font-size-huge: 2.25rem; /* 36px */
  --font-size-x-large: 1.5rem; /* 24px */
  --font-size-large: 1.125rem; /* 18px */
  --font-size-medium: 1rem; /* 16px */
  --font-size-small: 0.875rem; /* 14px */
  --font-size-tiny: 0.75rem; /* 12px */

  --light: 300;
  --normal: 400;
  --bold: 700;
}
/*

------------------------------------------------- COLOR ----------------------------------------------------------

*/
/**
 * @tokens Colors
 * @presenter Color
 */

:root {
  --color-text: #50586d; /* text default color */
  --color-text-secondary: #73798a; /* secondary content. e.g. description */
  --color-text-ternary: #969ba7;

  --color-brand: #3b93cd; /* primary buttons, throbber */
  --color-brand-hover: #4aa6e2;
  --color-brand-fade: #d8e9f5; /* hover of menu items & secondary buttons */

  --color-brand-secondary: #28d1ab;

  --color-investment-flow-icons: #c7d5df;
  --color-investment-background: var(--color-brand);

  --stepper-bullet-complete-background-color: #81c467;
  --stepper-bullet-complete-icon-color: #fff;
  --stepper-text-color: #fff;
  --stepper-text-active-color: #fff;
  --stepper-text-complete-color: #fff;
  --stepper-bullet-text-color: var(--color-text);
  --stepper-bullet-active-text-color: #fff;
  --stepper-bullet-disable-text-color: var(--color-text);

  --color-link: #3b93cd;

  --color-heading: var(--color-text); /* h1,h2,h3 headings */

  --color-hover-highlight: #dfe2ec30;

  --color-focus-border: #658de6; /* border of focused fields and buttons, background of focused menu item */
  --color-focus-outline: rgb(70 162 226 / 0.5); /* outer higlight of focused elemets */

  --color-error: #dd1831; /* errors: field border, text  */
  --color-error-light: #e20209; /* errors: alert */
  --color-error-darken: #bf0209; /* errors: alert */

  --color-success: #089f27;
  --color-warning: #ece83a;

  --color-header-dark: #20273a;

  --color-background: #fff;
  --color-page-background: #f6f7fa;
  --color-disabled-background: #f9fafb;
  --color-page-footer-text: #fff;
  --color-page-footer-background: #20273a;
}
/*
-- LAYOUT  ----------------------------------------------------------------------------------------------------------------
*/

:root {
  --gap-tiny: 0.375rem; /* 6px */
  --gap-small: 0.75rem; /* 12px */
  --gap: 1.5rem; /* 24px */
  --gap-large: 3rem; /* 48px */
  --gap-huge: 4.5rem; /* 72px */
}

:root {
  --width-container: calc(100vw - var(--gap) * 2);
  /*  @media (--desktop-large) {
    --width-container: 80rem;
  } */
}

:root {
  --width-container-one-fourth: calc((var(--width-container) - var(--gap) * 3) / 4);
  --width-container-one-third: calc((var(--width-container) - var(--gap) * 2) / 3);
  --width-container-one-second: calc((var(--width-container) - var(--gap)) / 2);

  --page-content-horizontal-padding: max(calc(50vw - var(--width-container) / 2), var(--gap));

  --layout-2-cols: repeat(2, minmax(21.25em, max-content));

  @media (--phone) {
    --layout-2-cols: 1fr;
  }
  --layout-3-cols: repeat(auto-fit, minmax(21.25em, max-content));

  @media (--phone) {
    --layout-3-cols: 1fr;
  }

  --header-height: 5rem;
}

:root {
  --color-addon-disabled: #b0b5c3;
  --color-border: #caccd3;
  --color-border-light: #f4f4f5;

  --focusable-box-shadow: 0 0 0 calc(3 * var(--focus-ring-width)) transparent;
  --focused-box-shadow: 0 0 0 var(--focus-ring-width) var(--color-focus-outline);
  --color-head-border-color: var(--color-link);

  --logo-height: 2.0625rem;
  --focus-ring-width: 2px;
  --color-table-border: var(--color-border-light);
  /* ------------------------------------ LOGO -------------------------------------- */
  --color-table-row-hover: #f8f8f8;
  --logo-width: 8rem;
}
/*

------------------------------------ INPUTS --------------------------------------

*/
/**
 * @tokens Form Colors
 * @presenter Color
 */

:root {
  --field-label-color: #969ba7; /* field label */
  --field-label-disabled-color: #969ba7; /* field label (disabled) */
  --field-border-color: #caccd3; /* field border */

  --field-hover-border-color: var(--color-brand); /* field border (hover) */
  --field-focus-border-color: var(--color-brand);
  --field-disabled-border-color: #e0e0e0;
  --field-read-only-border-color: #e0e0e0;

  --field-background-color: #fff; /* field control background */
  --field-hover-background-color: #fff; /* field control background (hover) */
  --field-focus-background-color: #fff; /* field control background (focus) */
  --field-disabled-background-color: var(--color-page-background);
  --field-read-only-background-color: var(--color-page-background);

  --field-text-color: var(--color-text); /* field control value */
  --field-hover-text-color: var(--color-text); /* field control value (hover) */
  --field-focus-text-color: var(--color-text); /* field control value (focus) */
  --field-disabled-color: var(--color-text-ternary);
  --field-read-only-color: var(--field-text-color);

  --field-invalid-border-color: var(--color-error);

  --field-addon-color: var(--color-brand);
  --field-addon-focus-color: var(--field-focus-border-color);
  --field-addon-disabled-color: var(--color-addon-disabled);

  --field-placeholder-color: var(--color-text-ternary);

  --field-disabled-option-color: var(--color-text-ternary);
  --checkbox-border-color: var(--color-brand);
}
/* SIZING */
:root {
  --field-border-width: 1px;
  --field-border-radius: 0.25rem;

  --checkbox-border-width: 1px;
  --checkbox-tick-size: 1em;

  --field-label-font-size: var(--font-size-small);
  --field-label-line-height: normal;
  --field-label-font: var(--field-label-font-size) / var(--field-label-line-height)
    var(--font-family);

  --field-height: 2.75rem;

  --field-line-height: 1.5rem;

  --field-padding-vertical: calc(var(--field-height) / 2 - var(--field-line-height) / 2);
  --field-padding-horizontal: 0.5rem;
  --field-padding: var(--field-padding-horizontal) var(--field-padding-vertical);

  --field-gap: 1rem;

  --field-addon-width: 3rem;

  --field-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.15s ease-in-out;
}
/*

------------------------------------ BUTTONS --------------------------------------

*/

:root {
  /* PRIMARY */
  --button-primary-background-color: var(--color-brand);
  --button-primary-border-color: transparent;
  --button-primary-color: #fff;
  --button-primary-hover-background-color: var(--color-brand-hover);
  /* SECONDARY */
  --button-secondary-background-color: #f6f6f7;
  --button-secondary-border-color: #caccd3;
  --button-secondary-color: var(--color-text);
  --button-secondary-hover-background-color: var(--color-brand-fade);
  /* ERROR / DANGER */
  --button-alert-color: var(--color-error-darken);
  --button-alert-hover-color: var(--color-error);
}

:root {
  /* ---------------------------------- SECTIONS ------------------------------------ */
  --section-background-color: #fff;
  --section-box-shadow-color: rgb(125 138 208 / 0.12);
  --section-box-shadow: 0 0 0 1px #e9ebf1, 0 0.25rem 2rem 0 var(--section-box-shadow-color);
  --section-box-shadow-hover: 0 4rem 4rem 0 rgb(125 138 208 / 0.21);
  --section-border-radius: 0.625rem;
  --section-padding: var(--gap);
  --section-border: none;
  /* ------------------------------------ CARD -------------------------------------- */
  --card-background-color: #fff;
  --card-box-shadow-color: rgb(125 138 208 / 0.12);
  --card-box-shadow: 0 0 0 1px #e9ebf1, 0 0.25rem 2rem 0 var(--section-box-shadow-color);
  --card-box-shadow-hover: 0 4rem 4rem 0 rgb(125 138 208 / 0.21);
  --card-border-radius: 0.625rem;
  --card-padding: var(--gap);
  --card-border: none;
  /* --------------------------------- ALTERNATE ------------------------------------ */
  --alternate-content-text-color: currentColor;
  --alternate-content-background-color: transparent;
}

html {
  font-size: var(--font-size-base-desktop);
  height: 100%;
  height: -webkit-fill-available;
  width: 100vw;

  @media (--tablet-) {
    font-size: var(--font-size-base-tablet);
  }
  @media (--phone) {
    font-size: var(--font-size-base-phone);
  }
  @media (--desktop-large) {
    font-size: var(--font-size-base-desktop-large);
  }
}

body {
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  margin: 0;
  max-width: 100vw;
  min-height: 100vh;
  /* mobile Safari viewport bug fix */
  min-height: -webkit-fill-available;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100vw;
}

#root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  position: relative;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: var(--width-container);
  padding: 0 1rem;
}

button,
select,
input,
textarea {
  font: inherit;
}

b,
strong {
  font-weight: var(--bold);
}

a:any-link {
  color: var(--color-link);
}

h1,
h2,
h3 {
  color: var(--color-heading);
  font-family: var(--font-family-heading);
  font-weight: normal;

  span {
    display: block;
    font-family: var(--font-family);
    font-size: var(--font-size-medium);
    line-height: 1.5;
  }
}

h1 {
  font-size: var(--font-size-x-large);
  line-height: 1.2;
  margin: 0;

  & + p {
    margin-top: 1rem;

    @media (--desktop-large) {
      margin-top: 2rem;
    }
  }
}

h2 {
  font-size: var(--font-size-x-large);
  line-height: 1.2;
  margin: 0 0 0.75rem;
}

h3 {
  font-size: var(--font-size-large);
  line-height: 1.2;
  margin: 0 0 0.75rem;
}

h4 {
  font-size: var(--font-size-medium);
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 0.75rem;
}

p {
  font-size: var(--font-size-medium);
  line-height: 1.5;
  margin: 0;

  &.secondary {
    color: var(--color-text-secondary);
    font-size: var(--font-size-small);
  }

  &.tiny {
    color: var(--color-text-secondary);
    font-size: var(--font-size-tiny);
  }

  &.multi-line {
    white-space: pre-line;
  }

  &:not(:last-child) {
    margin-bottom: var(--gap);
  }
}

ul:not([class]) {
  font-size: var(--font-size-medium);
  list-style: none;
  padding: 0 0 0 1em;

  & > li {
    line-height: 1.5;
    margin-bottom: 1em;
    position: relative;

    &::before {
      background-color: var(--color-brand);
      content: '';
      display: inline-block;
      height: 0.5em;
      left: -1em;
      position: absolute;
      top: 0.5em;
      width: 0.5em;
    }
  }
}

hr {
  appearance: none;
  background-color: var(--field-border-color);
  border: 0;
  display: block;
  height: 1px;
  margin: 0 0 1.75rem;
  width: 100%;
}

iframe {
  border: 0;
}

img.stretch {
  border-radius: var(--section-border-radius);
  max-width: 100%;
  object-fit: cover;
  object-position: center;

  @media (--tablet) {
    height: 100%;
    overflow: hidden;
  }
}
